import React, { useState } from 'react';
import './css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/aos.css';

export default function App() {
    const [txtFirstName, setTxtFirstName] = useState();
    const [txtLastname, setTxtLastName] = useState();
    const [txtEmail, setTxtEmail] = useState();
    const [txtPhoneNumber, setTxtPhoneNumber] = useState();
    const [txtMessage, setTxtMessage] = useState();

    async function submitData() {      
      const data = "{'firstName':"+txtFirstName+",'lastName':"+txtLastname+",'email':"+txtEmail+",'phoneNumber':"+txtPhoneNumber+",'message':"+txtMessage+"}"
      fetch('/api/contactus', {
        method: 'POST',
        body: data
      })
    }
    return (
      // <!-- Begin page -->
      <div class="wrapper">
      {/* <!-- Start Page Content -->
          <!-- Back to top button --> */}
          <a id="backtop">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg>
          </a>
  
          {/* <!-- Topbar start -->  */}
          <nav class="navbar   primary-navbar" aria-label="Main navigation">
              <div class="container-fluid py-2 align-items-center">
                <div class="nav-brand"><a  href="#"><img alt=""  src="img/logo.png" /></a></div>
                <div class="justify-content-end menu-scroll" >
                    <ul class="navbar-nav my-3 my-md-0   d-flex flex-row gap-3 gap-md-4 gap-lg-5 ">
                      <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="#" id="dropdown02" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                          <ul class="dropdown-menu" aria-labelledby="dropdown02">
                            <li><a class="dropdown-item" href="#sce">Supply Chain Expertise</a></li>
                            <li><a class="dropdown-item" href="#cdp">Common Data Platform</a></li>
                          </ul>
                        </li>
                      <li class="nav-item">
                        <a class="nav-link"  href="#about">About</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#testimonials">Testimonials</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#blog">Blog</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#contact">Contact</a>
                      </li>
                      
                    </ul>  
  
                </div>
              </div>
          </nav> 
          {/* <!-- Topbar end --> 
  
          <!-- Hero start -->  */}
          <section class="hero">
             <div class="container-fluid"> 
                  <div class="row align-items-center">
                      <div class="col-md-6 col-lg-6"  >
                          <h2>
                              Grow more your <span>business</span> with SV Tech services
                          </h2>
                          <div class="row align-items-center py-4">
                              <div class="col-md-3 col-lg-3 mb-3 mb-lg-0">
                                  <div class="teams">
                                      <img alt=""  src="img/pic-1.png" class="team-1" />
                                      <img alt=""  src="img/pic-2.png" class="team-2" />
                                      <img alt=""  src="img/pic-3.png" class="team-3" /> 
                                  </div>
                              </div>
                              <div class="col-md-9 col-md-9">
                                  Make <b>the best team</b>  for your business 
                                  we help you to build a excellent team 
                              </div>
                          </div>
                          <a href="#contact" class="btn btn-primary btn-lg rounded-pill mb-4 mb-md-0 mt-4 ">Let’s Connect</a>
                      </div>
                      <div class="col-md-6 col-lg-6"  data-aos="fade-up">
                         <img alt=""  src="img/hero.png" class="img-mob" />
                           
                      </div>
                  </div>
              </div>    
          </section>
          {/* <!-- Hero end --> 
  
          <!-- Summary start -->  */}
          <section class="summary">
              <div class="container-fluid">
                  <div class="row border py-5">
                      <div class="col-12 col-md-6 col-lg-6 mb-5 mb-md-0 text-center text-md-start">
                          <div class="primary-heading">
                              <h2 class="heading-3">We helps you  to get clients  from worldwide and hire best employee for your business</h2>
                          </div> 
                      </div>
                      <div class="col-12 col-md-6 col-lg-6  ">
                          <div class="row  justify-content-end">
                              <div class="col-6 col-md-6 col-lg-4 text-center summary-list">
                                  <h2>12</h2>
                                  <p>Active Customers</p>
                              </div>
                              <div class="col-6 col-md-6 col-lg-4 text-center summary-list">
                                  <h2>4.5 / 5</h2>
                                  <p>Rating by customers</p>
                              </div>
                          </div>
                      </div>
                     
                     
                  </div>
   
              </div>   
          </section>
          {/* <!-- Summary end --> 
  
          <!-- Service start -->  */}
          <section id="sce">
              <div class="container-fluid">
                  <div class="row  py-5 my-5 align-items-center">
                      <div class="col-12 col-md-6 col-lg-5">
                          <div class="primary-heading">
                              <p class="punchline">Service</p>
                              <h2 class="heading-1">Supply Chain Expertise</h2>
                              <p class="para-display">Build a complex and comprehensive supply chain management system and leverage our expertise in supply chain management to reduce costs and improve processes.
                              </p>
                              <p class="para">Establish an agile, resilient, and sustainable supply chain to navigate unexpected disruptions and support your business goals with optimized operations.
                              </p>
                              <a href="#contact" class="btn btn-primary btn-lg rounded-pill  mt-4">Let’s Connect</a>
                          </div> 
                      </div>
                      <div class="col-12 col-md-6 col-lg-7 text-md-end mt-4 mt-md-0"  data-aos="fade-up">
                         <img alt=""  src="img/img-scm.png" class="img-mob" />
                      </div>
                     
                     
                  </div> 
              </div>
          </section>
          {/* <!-- Service end -->
  
          <!-- Service start -->  */}
           <section id="cdp">
              <div class="container-fluid">
                  <div class="row  py-5 my-5 align-items-center">
                      <div class="col-12 col-md-6 col-lg-5">
                          <div class="primary-heading">
                              <p class="punchline">Service</p>
                              <h2 class="heading-1">Common Data Platform </h2>
                              <p class="para-display">The growing complexity of data regulations, varied data frameworks, and data governance issues are a few of the major challenges in achieving data-driven success today.
                              </p>
                              <p class="para">SVTech’s common data platform navigates these challenges with a comprehensive, enterprise-grade data management framework that can ingest any data type across all data lakes. It enables data-driven decisions to power advanced analytics while ensuring governance and compliance.
                              </p>
                              <a href="#contact" class="btn btn-primary btn-lg rounded-pill  mt-4">Let’s Connect</a>
                          </div> 
                      </div>
                      <div class="col-12 col-md-6 col-lg-7 text-md-end mt-4 mt-md-0"  data-aos="fade-up">
                         <img alt=""  src="img/img-cdp.png" class="img-mob" />
                      </div>
                     
                     
                  </div> 
              </div>
          </section>
          {/* <!-- Service end -->
  
          <!-- About start -->  */}
          <section class="about gap" id="about">
              <div class="container-fluid">
                  <div class="row  align-items-center">
                      <div class="col-md-6 col-lg-5">
                          <div class="primary-heading">
                              <p class="punchline">About SV Tech  services</p>
                              <h2 class="heading-2">Startup company having excellent team </h2>
                              <p class="para-display">The growing complexity of data regulations, varied data frameworks, and data governance issues are a few of the major challenges in achieving data-driven success today.
                              </p>
                              <ul class="list-unstyled list-check">
                                  <li><img alt=""  src="img/icon-check.png" class="icon-check" /> Supply chain management</li>
                                  <li><img alt=""  src="img/icon-check.png" class="icon-check" />Team hiring</li>
                                  <li><img alt=""  src="img/icon-check.png" class="icon-check" /> 24/7 assistance</li>
                              </ul>
                              <a href="#contact" class="btn btn-white btn-lg rounded-pill  mt-3">Let’s Connect</a>
                          </div> 
                      </div>
   
                      <div class="col-md-6 col-lg-7  text-md-end"  data-aos="fade-up">
                         <img alt=""  src="img/img-about.png" class="img-mob" />
                      </div>
                     
                     
                  </div> 
              </div>
          </section>
          {/* <!-- About end -->
  
          <!-- Testimonials start -->  */}
          {/*<section class="testimonials gap" id="testimonials"  data-aos="fade-up">
              <div class="container-fluid">
                  <div class="primary-heading text-center">
                      <p class="punchline">Happy Customers  Testimonials</p>
                      <h2 class="heading-2">Don’t just take our words</h2>
                  </div> 
                  <div class="row my-5 py-3 ">
                      <div class="col-md-6 col-lg-6 mb-5 mb-md-0">
                          <div class="row">
                              <div class="col-lg-3">
                                  <img alt=""  src="img/img-client-1.png" class="img-fluid mb-3 mb-md-0" />
                              </div>
                              <div class="col-lg-9">
                                  <div class="testimonial">
                                      <div class="tst-rate">
                                        <img alt=""  src="img/icon-star.png" /> 
                                        <img alt=""  src="img/icon-star.png" /> 
                                        <img alt=""  src="img/icon-star.png" /> 
                                        <img alt=""  src="img/icon-star.png" />
                                      </div>
                                      <div class="tst-msg">"We love Sv tech Services! Our company get more growth after taking their service.”</div>
                                      <div class="tst-by">Jenny Wilson <span>Grower.io</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                          <div class="row">
                              <div class="col-lg-3">
                                  <img alt=""  src="img/img-client-2.png" class="img-fluid mb-3 mb-md-0" />
                              </div>
                              <div class="col-lg-9">
                                  <div class="testimonial">
                                      <div class="tst-rate"><img alt=""  src="img/icon-star.png" /> 
                                      <img alt=""  src="img/icon-star.png" /> 
                                      <img alt=""  src="img/icon-star.png"/> <img alt=""  src="img/icon-star.png"/></div>
                                      <div class="tst-msg">"Much Satisfied with support team also their team member, getting more client for our business ."</div>
                                      <div class="tst-by">Devon Lane <span>Grower.io</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </section>*/}
          {/* <!-- Testimonials end -->*/}
  
          {/* <!-- Clients start -->  */}
           <section class="testimonials gap" id="clients"  data-aos="fade-up">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-4 col-lg-6 text-center text-md-start pb-5 pb-md-0">
                        <div class="primary-heading ">
                            <h2 class="heading-3 mb-0">Few of our reputed clients</h2>
                        </div> 
                    </div>
                    <div class="col-md-8 col-lg-6 text-center text-lg-end">
                        <div class="row row-cols-1 gap-4 row-cols-md-2 gap-md-0  row-cols-lg-3  gap-lg-3 align-items-center  ">
                            <div class="col"><img src="img/logo-syrencloud.png" /></div>
                            <div class="col"><img src="img/logo-pravid.png" /></div>
                            
                        </div>
                    </div>
                </div>
              
            </div>
        </section>
        {/* <!-- Clients end --> */}
        {/* <!-- Blog start -->  */}
          <section class="blog gap" id="blog"  data-aos="fade-up">
              <div class="container-fluid">
                  <div class="primary-heading text-center">
                      <p class="punchline">Learn New Technology Solutions</p>
                      <h2 class="heading-2">Read our blog</h2>
                  </div> 
                  <div class="row my-5">
                      <div class="col-lg-10 mx-auto">
                          <div class="row">
                              <div class="col-md-4 col-lg-4">
                                  <a class="card border-0 slide-up" href="">
                                      <img alt=""  src="img/img-post-1.png" />
                                      <span class="card-body px-0">
                                          <span class="card-title">Most popular design systems to learn from in 2022</span>
                                          <span class="card-text">Design Systems</span>
                                      </span>
                                  </a>
                              </div>
                              <div class="col-md-4 col-lg-4">
                                  <a class="card border-0 slide-up" href="">
                                      <img alt=""  src="img/img-post-2.png" />
                                      <span class="card-body px-0">
                                          <span class="card-title">Understanding accessibility makes you a better </span>
                                          <span class="card-text">Accessibility</span>
                                      </span>
                                  </a>
                              </div>
                              <div class="col-md-4 col-lg-4">
                                  <a class="card border-0 slide-up" href="">
                                      <img alt=""  src="img/img-post-3.png" />
                                      <span class="card-body px-0">
                                          <span class="card-title">15 best tools that will help you build your website</span>
                                          <span class="card-text">Tech</span>
                                      </span>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
                 
              </div>    
  
          </section>
          {/* <!-- Blog end -->
  
          <!-- Contact start --> */}
          <section class="contact gap" id="contact">
              <div class="container-fluid">
                  <div class="row align-items-center">
                      <div class="col-md-6 col-lg-5">
                          <div class="primary-heading">
                              <p class="punchline">Available Support Team </p>
                              <h2 class="heading-2">Love to hear from you, Get in touch</h2>
                          </div> 
                          <div id="form-message-warning" class="mb-2"></div> 
                          <div id="form-message-success" class="mb-2">Your message was sent, thank you!</div>
  
                          <form class="row g-3" id="contactForm" method="POST"  name="contactForm" >
                              <div class="col-md-6">
                                <label for="" class="form-label">First Name</label>
                                <input type="text" class="form-control" id="firstname" name="firstname" value={txtFirstName} onChange={(e)=>setTxtFirstName(e.currentTarget.value)} />
                                <div class="help-block with-errors"></div>
                              </div>
                              <div class="col-md-6">
                                  <label for="" class="form-label">Last Name</label>
                                  <input type="text" class="form-control" id="lastname" name="lastname"  value={txtLastname} onChange={(e)=>setTxtLastName(e.currentTarget.value)} />
                              </div>
                              <div class="col-12">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control" id="email" name="email" placeholder=""  value={txtEmail} onChange={(e)=>setTxtEmail(e.currentTarget.value)} />
                              </div>
                              <div class="col-12">
                                <label for=" " class="form-label">Phone Number</label>
                                <input type="text" class="form-control" id="phone" name="phone" placeholder=""  value={txtPhoneNumber} onChange={(e)=>setTxtPhoneNumber(e.currentTarget.value)} />
                              </div>
                              <div class="col-12">
                                <label for="" class="form-label">Message</label>
                                <textarea class="form-control" rows="3" id="message" name="message"  value={txtMessage} onChange={(e)=>setTxtMessage(e.currentTarget.value)}></textarea>  
                              </div>
                              <div class="col-12">
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" name="agree" id="agree" />
                                      <label class="form-check-label" for="agree">
                                          You agree to our friendly privacy policy.
                                      </label>
                                  </div>
                              </div>
                              <div class="col-12 pt-3">
                                <button  type="submit"  id="form-submit" class="btn btn-lg rounded-pill btn-primary w-100" onClick={submitData}>Send Message</button>
                              </div>
                              <div id="msgSubmit" class="h5 text-center hidden"></div>
  
                            </form>
                      </div>
                      <div class="col-md-6 col-lg-7 ">
                          <div class="text-md-end"  data-aos="fade-up">
                              <img alt=""  src="img/img-contact.png" class="img-mob" />
                          </div>
                          <div class="row justify-content-end">
                              <div class="col-md-12 col-lg-5">
                                  <div class="contact-info">
                                      <label>Address</label>
                                      <p>Surabhi Gardens, # 200 Ft Bypass Road, Avilala, Tirupati, Andhra Pradesh 517501, India</p>
                                  </div>
                              </div>
                              <div class="col-md-12 col-lg-5">
                                  <div class="contact-info">
                                      <label>Let’s Connect</label>
                                      <p>+91-95151 11312</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          {/* <!-- Contact start -->
  
          <!-- Footer start --> */}
          <section class="footer">
              <div class="container-fluid  ">
                  <div class="row">
                      {/* <!-- Company start --> */}
                      <div class="col-12 col-md-12 col-lg-2 pb-3 pb-md-0">
                          <img alt=""  src="img/logo.png" />
                          <p class="pt-4 ft-text"></p>
                      </div>
                      {/* <!-- Company end --> */}
                      <div class="col-lg-2">
                          &nbsp;
                      </div>
                      {/* <!-- Common start --> */}
                      <div class="col-5 col-md-4 col-lg-2 pb-3 pb-md-0">
                          <h5>Company</h5>
                          <ul class="p-0 list-unstyled">
                              <li class="mb-3">
                                  <a href="#about">About</a>
                              </li>
                              {/* <li class="mb-3">
                                  <a href="#testimonials">Testimonials</a>
                              </li> */}
                              <li class="mb-3">
                                  <a href="#contact">Contact</a>
                              </li>
                          </ul>        
                      </div> 
                       {/* <!-- Common end -->
                       <!-- Service start --> */}
                      <div class="col-5 col-md-4 col-lg-2">
                          <h5>Service</h5>
                          <ul class="p-0 list-unstyled">
                              <li class="mb-3">
                                  <a href="#sce">Supply Chain Expertise</a>
                              </li>
                              <li class="mb-3">
                                  <a href="#cdp">Common Data Platform</a>
                              </li>
                          </ul>        
                      </div> 
                       {/* <!-- Service end --> */}
                       <div class="col-md-0 col-lg-1 order-md-2 order-lg-1">
                          &nbsp;
                      </div>
                       {/* <!-- Newsletter start --> */}
                      {/* <div class="col-6 col-md-4 col-lg-3 order-md-1 order-lg-2">
                          <h5>Newsletter</h5>
                          <div id="form-message-warning-1" class="mb-2"></div> 
                          <div id="form-message-success-1" class="mb-2">Your message was sent, thank you!</div>
                          <form class="row newsletter" id="subscribeForm"   method="POST"  name="subscribeForm">
                              <div class="col-12">
                                  <input type="text" class="form-control" id="email" name="email" placeholder="Enter your email address" />
                                  <div class="help-block with-errors "></div>
                              </div>
                              <div class="col-12 pt-3"> <button  type="submit"  id="form-submit" class="btn btn-md btn-primary w-100">Subscribe Now</button></div>
                              <div id="msgSubmit" class="h5 text-center hidden"></div>
                          </form>     
                      </div>  */}
                       {/* <!-- Newsletter end --> */}
                  </div>
                  <div class="row py-5 align-items-center">
                      <div class="text-center  col-12">
                          <p class="copyright m-0">&copy; 2022  SV Tech  Services | All Rights Reserved</p>
                      </div>
                  </div>
              </div>
          </section>    
          {/* <!-- Footer end --> */}
      </div>  
    );
  }